<template>
  <b-modal
    id="codi-info"
    title="Conoce como activar CoDi"
    ok-only
    ok-title="Cancelar"
    ok-variant="secondary"
  >
    <b-row class="d-flex justify-content-center">
      <b-col sm="auto" class="d-flex flex-column">
        <b-button
          href="https://youtu.be/Ywx-aXuqqUI"
          target="_blank"
          variant="primary"
          class="mb-1"
        >
          BBVA
        </b-button>
        <b-button
          href="https://youtu.be/UP1RvwyvmLA"
          target="_blank"
          variant="primary"
          class="mb-1"
        >
          Citibanamex
        </b-button>
        <b-button
          href="https://youtu.be/pfAvXmyZ8xQ"
          target="_blank"
          variant="primary"
          class="mb-1"
        >
          Santander
        </b-button>
        <b-button
          href="https://youtu.be/ELo20ro2GyE"
          target="_blank"
          variant="primary"
          class="mb-1"
        >
          Banorte
        </b-button>
        <b-button
          href="https://www.scotiabank.com.mx/servicios-bancarios/codi.aspx"
          target="_blank"
          variant="primary"
          class="mb-1"
        >
          Scotiabank
        </b-button>
        <b-button
          href="https://www.hsbc.com.mx/content/dam/hsbc/mx/documents/digital/hsbc-mexico/registro_codi_hsbc_mexico.pdf"
          target="_blank"
          variant="primary"
          class="mb-1"
        >
          HSBC
        </b-button>
        <b-button
          href="https://www.banregio.com/codi.php"
          target="_blank"
          variant="primary"
          class="mb-1"
        >
          Banregio
        </b-button>
        <b-button
          href="https://www.bancoppel.com/modal_bcopp/guia_codi_activacion_movil.html"
          target="_blank"
          variant="primary"
          class="mb-1"
        >
          Bancoppel
        </b-button>
        <b-button
          href="https://www.bancoazteca.com.mx/conocenos/uso-de-codi.html"
          target="_blank"
          variant="primary"
          class="mb-1"
        >
          Banco Azteca
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

export default {
  data() {
    return {
    };
  },
};

</script>