<template>
  <b-modal
    id="complete-codi-payment"
    title="Pago con CoDi"
    no-stacking
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    :disabled.sync="loading"
    @hidden="selectedIntent = null"
  >
    <validation-observer ref="accountCelRules" tag="form">
      <b-row>
        <b-col
          class="d-flex flex-column justify-content-center align-items-center"
          cols="12"
        >
          <b-img :src="require('@/assets/images/logo/CODI.svg')" width="150" />
        </b-col>
        <b-col cols="12">
          <div class="mx-2" v-if="!selectedIntent">
              Elige una de las opciones para realizar el
              pago
            <div class="my-1">
              <b-button
                block
                variant="primary"
                class="uppercase"
                @click="selectedIntent = 'notification'"
              >
                Notificación en tu celular
              </b-button>

              <b-button
                block
                variant="primary"
                class="uppercase"
                @click="selectedIntent = 'qrcode'"
              >
                CÓDIGO QR
              </b-button>
            </div>
          </div>
          <div v-if="selectedIntent === 'notification'" class="mx-2 mt-1">
            <h5 class="mb-0">Número de teléfono</h5>
            <small class="text-muted">
              Agrega tu número de teléfono para confirmar la operación
            </small>

            <b-form-group label-for="v-cel_number">
              <validation-provider
                #default="{ errors, valid }"
                name="Celular"
                rules="required|digits:10"
              >
                <b-form-input
                  id="v-cel_number"
                  v-model="order.cel_number"
                  placeholder="Celular a 10 dígitos"
                  size="lg"
                  type="number"
                  class="text-center"
                  :state="errors.length > 0 ? false : valid ? true : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div v-else-if="selectedIntent === 'qrcode'" class="mx-2 mt-1">
            <h5 class="mb-0">Código QR</h5>
            <small class="text-muted">
              Abre tu aplicación bancaria, dirígete a la sección de CoDi y escanea el código QR. También puedes guardar o hacer una captura de pantalla y subirla a tu aplicación bancaria dentro de la sección de CoDi.
            </small>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <b-col cols="12" class="d-flex justify-content-center align-items-center my-2">
      <b-button v-b-modal.codi-info variant="link">
        ¿Cómo activar CoDi? <em class="fas fa-info-circle" />
      </b-button>
    </b-col>
    <template #modal-footer>
      <b-button
        :disabled="loading"
        variant="outline-danger"
        @click="handleCancelCodiModal"
      >
        Cancelar
      </b-button>
      <div>
        <b-button
          v-if="selectedIntent"
          :variant=" 'success'"
          @click="completeOnlineOrder('codi', selectedIntent)"
        >
          <b-spinner v-if="loading" small />
          Confirmar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"

export default {
  data() {
    return {
      text: "ar",
      selectedIntent: null,
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    order: {
      type: Object,
      validator: (p) => {
        return ["object"].indexOf(typeof p) !== -1 || p === null
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
    completeOnlineOrder: {
      type: Function,
      required: true,
    },
    handleCancelCodiModal: {
      type: Function,
      required: true,
    },
  },
}
</script>
