export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function getDescriptionArray(description) {
  if (typeof description === "string") {
    return description.split(". ")
  } else {
    return []
  }
}
