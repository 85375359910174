<script>
export default {
  props: {
    selectedStoreProduct: {
      type: Object,
      default: () => null,
    },
  },
}
</script>

<template>
  <b-modal
    id="product-promotion-modal"
    title="Promoción del producto"
    class="modal-content"
    no-stacking
    no-close-on-esc
    ok-only
    ok-variant="success"
    ok-title="Cerrar"
    @ok="$bvModal.hide(`product-promotion-modal`)"
  >
    <div v-if="selectedStoreProduct" class="text-center">
      <h5 class="mt-1">
        <b-badge
          v-if="selectedStoreProduct.bestPromotion"
          pill
          :variant="'light-info'"
        >
          {{ "Si compras " + selectedStoreProduct.bestPromotion.needs_to_buy }}
          {{
            selectedStoreProduct.bestPromotion.needs_to_buy > 1
              ? "unidades te llevas "
              : "unidad te llevas "
          }}
        </b-badge>

        <!-- Price discount -->
        <b-badge
          v-if="
            selectedStoreProduct.bestPromotion &&
            selectedStoreProduct.bestPromotion.promo_type === 'price_discount'
          "
          pill
          :variant="'light-info'"
          class="mt-50"
        >
          {{ "un " + selectedStoreProduct.bestPromotion.discount + "% de " }}
          {{ selectedStoreProduct.bestPromotion.promo_type | promotionType }}
        </b-badge>
        <b-badge
          v-if="
            selectedStoreProduct.bestPromotion &&
            selectedStoreProduct.bestPromotion.promo_type === 'price_discount'
          "
          pill
          :variant="'light-info'"
          class="mt-50"
        >
          {{ "Ahorrando un total de " }}
          {{
            (selectedStoreProduct.bestPromotion.needs_to_buy *
              selectedStoreProduct.unit_price *
              selectedStoreProduct.bestPromotion.discount) /
            100
          }}
          {{ "MXN" }}
        </b-badge>

        <!-- Reward points -->
        <b-badge
          v-if="
            selectedStoreProduct.bestPromotion &&
            selectedStoreProduct.bestPromotion.promo_type === 'reward_points'
          "
          pill
          :variant="'light-info'"
          class="ml-50"
        >
          {{
            ("un " +
              selectedStoreProduct.bestPromotion.reward_points +
              "% de " +
              selectedStoreProduct.bestPromotion.promo_type)
              | promotionType
          }}
        </b-badge>
        <b-badge
          v-if="
            selectedStoreProduct.bestPromotion &&
            selectedStoreProduct.bestPromotion.promo_type === 'reward_points'
          "
          pill
          :variant="'light-info'"
          class="mt-50"
        >
          {{ "Equivalentes a $" }}
          {{
            (selectedStoreProduct.bestPromotion.needs_to_buy *
              selectedStoreProduct.unit_price *
              selectedStoreProduct.bestPromotion.reward_points) /
            100
          }}
          {{ "MXN" }}
        </b-badge>

        <!-- Free product -->
        <b-badge
          v-if="
            selectedStoreProduct.bestPromotion &&
            selectedStoreProduct.bestPromotion.promo_type === 'free_product'
          "
          pill
          :variant="'light-info'"
          class="ml-50"
        >
          {{ selectedStoreProduct.bestPromotion.needs_to_buy }} {{ "x" }}
          {{ selectedStoreProduct.bestPromotion.gets_you }}
        </b-badge>
        <b-badge
          v-if="
            selectedStoreProduct.bestPromotion &&
            selectedStoreProduct.bestPromotion.promo_type === 'free_product'
          "
          pill
          :variant="'light-info'"
          class="mt-50"
        >
          {{ "Ahorrando un total de " }}
          {{
            (selectedStoreProduct.bestPromotion.needs_to_buy -
              selectedStoreProduct.bestPromotion.gets_you) *
            selectedStoreProduct.unit_price
          }}
          {{ "MXN" }}
        </b-badge>
      </h5>
    </div>
  </b-modal>
</template>
