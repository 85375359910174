var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"modal-content",attrs:{"id":"product-promotion-modal","title":"Promoción del producto","no-stacking":"","no-close-on-esc":"","ok-only":"","ok-variant":"success","ok-title":"Cerrar"},on:{"ok":function($event){return _vm.$bvModal.hide("product-promotion-modal")}}},[(_vm.selectedStoreProduct)?_c('div',{staticClass:"text-center"},[_c('h5',{staticClass:"mt-1"},[(_vm.selectedStoreProduct.bestPromotion)?_c('b-badge',{attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s("Si compras " + _vm.selectedStoreProduct.bestPromotion.needs_to_buy)+" "+_vm._s(_vm.selectedStoreProduct.bestPromotion.needs_to_buy > 1 ? "unidades te llevas " : "unidad te llevas ")+" ")]):_vm._e(),(
          _vm.selectedStoreProduct.bestPromotion &&
          _vm.selectedStoreProduct.bestPromotion.promo_type === 'price_discount'
        )?_c('b-badge',{staticClass:"mt-50",attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s("un " + _vm.selectedStoreProduct.bestPromotion.discount + "% de ")+" "+_vm._s(_vm._f("promotionType")(_vm.selectedStoreProduct.bestPromotion.promo_type))+" ")]):_vm._e(),(
          _vm.selectedStoreProduct.bestPromotion &&
          _vm.selectedStoreProduct.bestPromotion.promo_type === 'price_discount'
        )?_c('b-badge',{staticClass:"mt-50",attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s("Ahorrando un total de ")+" "+_vm._s((_vm.selectedStoreProduct.bestPromotion.needs_to_buy * _vm.selectedStoreProduct.unit_price * _vm.selectedStoreProduct.bestPromotion.discount) / 100)+" "+_vm._s("MXN")+" ")]):_vm._e(),(
          _vm.selectedStoreProduct.bestPromotion &&
          _vm.selectedStoreProduct.bestPromotion.promo_type === 'reward_points'
        )?_c('b-badge',{staticClass:"ml-50",attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s(_vm._f("promotionType")(("un " + _vm.selectedStoreProduct.bestPromotion.reward_points + "% de " + _vm.selectedStoreProduct.bestPromotion.promo_type)))+" ")]):_vm._e(),(
          _vm.selectedStoreProduct.bestPromotion &&
          _vm.selectedStoreProduct.bestPromotion.promo_type === 'reward_points'
        )?_c('b-badge',{staticClass:"mt-50",attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s("Equivalentes a $")+" "+_vm._s((_vm.selectedStoreProduct.bestPromotion.needs_to_buy * _vm.selectedStoreProduct.unit_price * _vm.selectedStoreProduct.bestPromotion.reward_points) / 100)+" "+_vm._s("MXN")+" ")]):_vm._e(),(
          _vm.selectedStoreProduct.bestPromotion &&
          _vm.selectedStoreProduct.bestPromotion.promo_type === 'free_product'
        )?_c('b-badge',{staticClass:"ml-50",attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s(_vm.selectedStoreProduct.bestPromotion.needs_to_buy)+" "+_vm._s("x")+" "+_vm._s(_vm.selectedStoreProduct.bestPromotion.gets_you)+" ")]):_vm._e(),(
          _vm.selectedStoreProduct.bestPromotion &&
          _vm.selectedStoreProduct.bestPromotion.promo_type === 'free_product'
        )?_c('b-badge',{staticClass:"mt-50",attrs:{"pill":"","variant":'light-info'}},[_vm._v(" "+_vm._s("Ahorrando un total de ")+" "+_vm._s((_vm.selectedStoreProduct.bestPromotion.needs_to_buy - _vm.selectedStoreProduct.bestPromotion.gets_you) * _vm.selectedStoreProduct.unit_price)+" "+_vm._s("MXN")+" ")]):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }